import {
    Container,
    Header,
    Card,
    Icon,
    Menu,
    Divider
  } from "semantic-ui-react";
  import { Link, useNavigate  } from 'react-router-dom'


  const NavBar = (props) => {

  
    const navigate = useNavigate();
      return (
          <Menu fixed="top" style={{backgroundColor: "#0d96d4", color:"White"}}>
            <Link to="/calls"><Menu.Item style={{color: "White", paddingLeft: "2em", textShadow: "1px 1px 1px #5e5d5d", whiteSpace: "nowrap"}} link>Live Calls</Menu.Item></Link>
	    <span style={{ fontSize: '1.2rem', paddingLeft: '1em', paddingTop: '.6em', color: 'White', textShadow: "1px 1px 1px #5e5d5d", whiteSpace: "nowrap"}}>
               Pierce & King County Scanner
            </span>
          </Menu>
      );
    }
  
  
  export default NavBar;