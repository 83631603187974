import React, { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom'
import { createMedia } from "@artsy/fresnel";
import { Helmet } from 'react-helmet'
import AdsComponent from '../AdsComponent';
import "./Main.css";
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Menu,
  Segment,
  Sidebar,
  Transition
} from 'semantic-ui-react'

import { useDispatch } from 'react-redux'
import { useGetSystemsQuery } from "../features/api/apiSlice";

const AppMedia = createMedia({
  breakpoints: {
    mobile: 320,
    tablet: 768,
    computer: 992,
    largeScreen: 1200,
    widescreen: 1920
  }
});

const mediaStyles = AppMedia.createMediaStyle();
const { Media, MediaContextProvider } = AppMedia;

const HomepageHeading = ({ mobile, ipAddress }) => (
  <Container text style={{paddingBottom: '0px'}} >
    <Header
      as='h2'
      content='PUBLIC Police and Fire dispatch in Pierce & King County'
      style={{
        fontSize: mobile ? '1.5em' : '1.7em',
        color: "#FFF",
        fontWeight: 'normal',
        marginTop: mobile ? '0.5em' : '1.5em',
        marginBottom: mobile ? '0.5em' : '0em',
      }}
     />
    <br/>
    <p style={{marginBottom: "130px"}}>
      * Use of a police scanner in the commission of a crime is a felony *<br/>
      * Your IP address is: {ipAddress} *
    </p>
  </Container>
)

const DesktopContainer = ({ children, ipAddress }) => {  
  const [fixed, setFixed] = useState(false);
  const hideFixedMenu = () => setFixed(false);
  const showFixedMenu = () => setFixed(true);
  
  return (
    <>
    <Helmet>
      <title>Pierce & King County Scanner</title>
      <meta property="og:title" content="Pierce & King County Scanner" />
      <meta property="og:description" content="Pierce & King County Police Radio Scanner and archives." />
      <meta property="og:image" content="s3.amazonaws.com/scannerlivestream/thumb-event.jpg" />
    </Helmet>
    <Media greaterThanOrEqual="tablet">
      <div className="relative">
        <div className="static-gradient blue absolute z-0" >
        <div className="static-gradient-bg absolute z-5"></div>
      </div>
      <Segment
        textAlign='center'
        style={{ padding: '1em 0em', borderBottom: '0px', boxShadow: 'none', height: '350px' }}
        vertical
      >
        <Menu
          fixed={fixed ? 'top' : null}
          inverted={true}
          pointing={false}
          secondary={true}
          size='huge'
          style={{  marginRight: '0px'}}
        >
          <Container>
            <Menu.Item ><Header as='h3' inverted>{process.env.REACT_APP_SITE_NAME}</Header></Menu.Item>
            <Link to="/calls"><Menu.Item link >Listen</Menu.Item></Link>
            <Link to="/events"><Menu.Item link >Events</Menu.Item></Link>
            <Link to="/about"><Menu.Item link >About</Menu.Item></Link>
          </Container>
        </Menu>
        <center>
            <AdsComponent />
        </center>
        <HomepageHeading ipAddress={ipAddress} />
      </Segment>
      </div>
      {children}
    </Media>
    </>
  )
}

const MobileContainer = ({ children, ipAddress }) => {
  const [sidebarOpened, setSidebarOpened] = useState(false);
  const handlePusherClick = () => setSidebarOpened(!sidebarOpened);
  const handleToggle = () => setSidebarOpened(!sidebarOpened);

  return (
    <Media lessThan="tablet">
      <Sidebar.Pushable>
        <Sidebar as={Menu} animation='uncover' inverted vertical visible={sidebarOpened}>
          <Menu.Item active>Home</Menu.Item>
          <Menu.Item ><Link to="/calls">Calls</Link></Menu.Item>
          <Menu.Item ><Link to="/events">Events</Link></Menu.Item>
          <Menu.Item ><Link to="/about">About</Link></Menu.Item>
        </Sidebar>

        <Sidebar.Pusher
          dimmed={sidebarOpened}
          onClick={handlePusherClick}
          style={{ minHeight: '100vh' }}
        >
        <div className="relative">
              <div className="static-gradient blue absolute z-0" >
                <div className="static-gradient-bg absolute z-5"></div>
              </div>
          <Segment
            textAlign='center'
            style={{ minHeight: 350, padding: '1em 0em', borderBottom: '0px', boxShadow: 'none', height: '300px'}}
            vertical
          > 
            <Container>
              <Menu inverted secondary size='large'>
                <Menu.Item onClick={handleToggle}>
                  <Icon name='sidebar' />
                </Menu.Item>
                <Menu.Item header>{process.env.REACT_APP_SITE_NAME}</Menu.Item>
              </Menu>
            </Container>
            <center>
              <AdsComponent />
            </center>
            <HomepageHeading mobile ipAddress={ipAddress} />
          </Segment>
          </div>
          {children}
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </Media>
  )
}

const ResponsiveContainer = ({ children, ipAddress }) => (
  <div>
    <DesktopContainer ipAddress={ipAddress}>{children}</DesktopContainer>
    <MobileContainer ipAddress={ipAddress}>{children}</MobileContainer>
  </div>
)

const Main = () => {
  <style>{mediaStyles}</style>
  const [ipAddress, setIpAddress] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => setIpAddress(data.ip))
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);

  return (
  <MediaContextProvider>
  <ResponsiveContainer ipAddress={ipAddress}>
    <Segment style={{ padding: '4em 0em' }} vertical>
      <Grid container stackable verticalAlign='middle'>
        <Grid.Row>
          <Grid.Column textAlign='center' computer={10} tablet={16} mobile={16}>
            <Header as='h3' style={{ fontSize: '2em' }}>Police, Fire and EMS, all in one.</Header>
            <p style={{ fontSize: '1.33em' }}>
              Listen to your local first responders in real time.
            </p>
          </Grid.Column>
          <Grid.Column textAlign='center' computer={6} tablet={16} mobile={16}>
            <Link to="/calls">
              <Button primary size='huge' id="listen" animated onClick={(e) => navigate("/calls/")}>
                <Button.Content visible><Icon name='headphones' />
                <Icon name='right arrow' /></Button.Content>
                <Button.Content hidden>Listen</Button.Content>
              </Button>
            </Link>
          </Grid.Column>            
        </Grid.Row>
      </Grid>
    </Segment>
    <Segment style={{ padding: '0em' }} vertical>
      <Grid celled='internally' columns='equal' stackable>
        <Grid.Row textAlign='center'>
          <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
            <Header  as='h3' style={{ fontSize: '2em' }}>
              <Icon color='#0d96d4' name='list' />All The Calls
            </Header>
            <p style={{ fontSize: '1.33em' }}>Every call, on every talkgroup is recorded</p>
          </Grid.Column>
          <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
            <Header as='h3' style={{ fontSize: '2em' }}>
              <Icon color='#0d96d4' name='checked calendar' />
              Go back in time
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              Missed a call? Not a problem, everything is archived!
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
    <Segment inverted vertical style={{ padding: '5em 0em' }} id="footer">
      <div class="centered aligned" style={{fontSize: "large",fontWeight:"strong" ,textAlign: "center"}}>Miss the old scanner? It's available <a href="https://original.scannerlivestream.com">here</a></div>
      <Container>
        <Grid divided inverted stackable>
          <Grid.Row>
            <Grid.Column width={6}>

            </Grid.Column>
            <Grid.Column width={4}>
            <center>
              <div class="horizontal_ad">
                <AdsComponent />
              </div>
            </center>
            </Grid.Column>
            <Grid.Column width={6}>


            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  </ResponsiveContainer>
</MediaContextProvider>
  );
};

export default Main;
