import React, { useEffect } from 'react';

function AdComponent({ adSlot, adStyle, adFormat, adResponsive }) {
  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  return (
    <ins 
      className="adsbygoogle"
      style={{ display: "block", minWidth: "400px", maxWidth: "800px", width: "100%", minHeight: "90px", maxHeight: "250px", height: "100px"}}
      data-ad-client="ca-pub-7987249781782871"
      data-ad-slot={adSlot}
      data-ad-format="horizontal"
      data-full-width-responsive="true"
    />
  );
}

export default AdComponent;