import React, { Component } from "react";
import { Link, useNavigate } from 'react-router-dom'
import AdsComponent from '../AdsComponent';
import {
  Container,
  Header,
  Card,
  Icon,
  Menu,
  Divider,
  Table,
  List
} from "semantic-ui-react";
import NavBar from "../Common/NavBar"
import { useGetEventsQuery } from "../features/api/apiSlice";
import { Helmet } from 'react-helmet'
// ----------------------------------------------------

const ListEvents = (props) => {

  const dateRange = (start, end) => {
    if (start.getYear() != end.getYear()) {
      return start.toLocaleDateString('en-us', { weekday: "short", year: "numeric", month: "short", day: "numeric" }) + " " + start.toLocaleTimeString() + " - " + end.toLocaleDateString('en-us', { weekday: "short", year: "numeric", month: "short", day: "numeric" }) + " " + end.toLocaleTimeString();
    } else if (start.getMonth() != end.getMonth()) {
      return start.toLocaleDateString('en-us', { weekday: "short", month: "short", day: "numeric" }) + " " + start.toLocaleTimeString() + " - " + end.toLocaleDateString('en-us', { weekday: "short", month: "short", day: "numeric" }) + " " + end.toLocaleTimeString();

    } else if (start.getDay() != end.getDay()) {
      return start.toLocaleDateString('en-us', { weekday: "short", month: "short", day: "numeric" }) + " " + start.toLocaleTimeString() + " - " + end.toLocaleDateString('en-us', { weekday: "short", day: "numeric" }) + " " + end.toLocaleTimeString();

    } else {
      return start.toLocaleDateString('en-us', { weekday: "short", month: "short", day: "numeric" }) + " " + start.toLocaleTimeString() + " - " + end.toLocaleTimeString();

    }


  }

  const { data: events, isSuccess } = useGetEventsQuery();
  const navigate = useNavigate();
  //<Table.Cell>{event.shortNames.map( shortName => <Link to={"/"+shortName}>{shortName}</Link>  )}</Table.Cell>}
  return (
    <>
    <Helmet>
      <title>Pierce County Scanner - Events</title>
      <meta property="og:title" content="Pierce & King County Scanner - Events" />
      <meta property="og:description" content="Notable events from Pierece & King County." />
      <meta property="og:image" content="https://s3.amazonaws.com/scannerlivestream/thumb-event.jpg" />
    </Helmet>
    <div>
      <NavBar />
      <div class= "container horizontal_ad"> 
        <center>
          <AdsComponent adSlot="7461549169" />
        </center>
      </div>
      <Container >
        <Divider horizontal style={{ paddingTop: "2em", paddingBottom: ".5em" }}><Header as="h1">Scanner Events<Icon name='rss' /></Header></Divider>
        <span style={{ color: 'red' }}>
          <strong>Disclaimer:</strong> Events are user-generated. The content and accuracy of the titles and descriptions do not reflect the views of scannerlivestream.com.
        </span>        
        <Table basic="very">
          <Table.Body>

            {isSuccess && events.map((event, index) =>
              <Table.Row key={index}>
                <Table.Cell><Link to={"/events/" + event._id}><Header as='h3'>{event.title}</Header></Link></Table.Cell>
                <Table.Cell><List>{event.shortNames.map( shortName => <List.Item>{shortName}</List.Item>  )}</List></Table.Cell>
                <Table.Cell>{event.description}</Table.Cell>
                <Table.Cell>{event.numCalls} Calls</Table.Cell>
                <Table.Cell>{dateRange(new Date(event.startTime), new Date(event.endTime))}</Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Container>
      <div class= "container horizontal_ad"> 
        <center>
          <AdsComponent adSlot="7461549169" />
        </center>
      </div>
    </div>
  </>
  );
}


export default ListEvents;
