import React, { useState } from "react";
import {
  Table,
  Icon,
  Label
} from "semantic-ui-react";

import { useGetTalkgroupsQuery } from '../features/api/apiSlice'


const EventCallItem = (props) => {
  const call = props.call;
  const activeCall = props.activeCall;
  const { data: talkgroupsData, isSuccess: isTalkgroupsSuccess } = useGetTalkgroupsQuery(call.shortName);


  const time = new Date(call.time);



  let rowSelected = {};






  if (activeCall) {
    rowSelected = {
      positive: true,
      color: "blue",
      key: "blue",
      inverted: "true"
    }
  }
  let talkgroup;
  if ((typeof talkgroupsData == 'undefined') || (typeof talkgroupsData.talkgroups[call.talkgroupNum] == 'undefined')) {
    talkgroup = call.talkgroupNum;
  } else {
    talkgroup = talkgroupsData.talkgroups[call.talkgroupNum].description;
  }

  const cirlceStyle = {width:"4px",
              margin:"6px",
              height: "4px",
              borderRadius:"50%"}


  let playStatus = (<></>)
  if (activeCall) {
    playStatus = (<Icon name="play"  size='small' color="green"/>)
    
  } else {
    playStatus = (<div style={cirlceStyle}/>)
  }
  //const [transcriptVisible, setTranscriptVisible] = useState(true);
  const [transcriptVisible, setTranscriptVisible] = useState(!!call.transcript && call.transcript.length > 0);
  return (
  <>
    <Table.Row class="rowone" onClick={(e) => props.onClick({ call: call }, e)} {...rowSelected}>
      <Table.Cell style={{ borderBottom: 'none' }}>{playStatus}</Table.Cell>
      <Table.Cell style={{ borderBottom: 'none' }}>  {call.len} </Table.Cell>
      <Table.Cell style={{ borderBottom: 'none' }}> {talkgroup} </Table.Cell>
      <Table.Cell style={{ borderBottom: 'none' }}> {`${time.toLocaleTimeString()} ${time.toLocaleDateString() !== new Date().toLocaleDateString() ? time.getMonth() + 1 + '/' + time.getDate() : ''}`} </Table.Cell>
      <Table.Cell style={{ borderBottom: 'none' }}> {call.shortName}</Table.Cell>
    </Table.Row>
    {transcriptVisible && (
      <Table.Row class="rowtwo" onClick={(e) => props.onClick({ call: call }, e)} {...rowSelected} data-callid={call._id}>
        <Table.Cell style={{ borderTop: 'none' }}><i aria-hidden="true" class="black closed captioning outline icon"></i></Table.Cell>
        <Table.Cell style={{ borderTop: 'none' }} colSpan={4}>{call.transcript}</Table.Cell>
      </Table.Row>
    )}
  </>
  );
}

export default EventCallItem;
