import React from "react";
import { Link } from 'react-router-dom'
import { Container, Header, Divider, Icon, Menu, Button } from "semantic-ui-react";
import AdsComponent from '../AdsComponent';

const AboutComponent = () => {
  return (
    <div>
      <Menu fixed="top" style={{backgroundColor: "#0d96d4", color:"White"}}>
        <Link to="/"><Menu.Item style={{color:"White"}} link><Icon name='arrow left' /> Home</Menu.Item></Link>
        <Link to="/calls"><Menu.Item style={{color:"White"}} link>Listen</Menu.Item></Link>
      </Menu>
      <div class="horizontal_ad">
      <center>
        <AdsComponent adSlot="7461549169" />
      </center>
      </div>
      <Container text>
        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
        <ins className="adsbygoogle"
          style={{display: "block"}}
          data-ad-client="ca-pub-7987249781782871"
          data-ad-slot="1779188550"
          data-ad-format="auto"
          data-full-width-responsive="true">
        <script>
          (adsbygoogle = window.adsbygoogle || []).push({});
        </script>
        </ins>
        <Divider horizontal style={{ paddingTop: "4em", paddingBottom: "2em" }}><Header as="h1">About<Icon name='compass' /></Header></Divider>

        <div class="centered aligned" style={{fontSize: "large", fontWeight: "strong", color: "red", textAlign: "center"}}>Miss the old scanner? It's still available <a href="https://original.scannerlivestream.com">here</a></div><br/>

        <p>We capture public Police, Fire and EMS radio communications on PSRS, PSERN and <a href="https://southsound911.org/about/">SouthSound911</a> regional radio network in Pierce & King County Washington. We do not capture encrypted radio talk groups</p>

        <p>All recorded audio on this website is PUBLIC and may be used and distributed freely with no restrictions</p>

        <p>This website is provided as is without warranty. If the website or radio feeds go down we will try to restore them as soon as possible. As with any website there may be growing pains and upgrades will be needed to support more active users. During major events this website may not be able to handle the traffic and may slow down or go offline. We want to make this website available to everyone but also keep the costs down.</p>

        <p>Use of a police scanner in the commission of a crime is a felony. Any use of this website for illegal activities is strictly forbidden. We reserve the right to provide information to law enforcement if such illegal activity occurs. Information including but not limited to: Your IP Address, location, ISP or cellular service provider. Your information will never be shared with anyone for any other reason.</p>

        <p>If you would like to report issues, request a feature, or to request an audio recording be removed for privacy reasons please email records@scannerlivestream.com with a link to the audio file or the exact time of the recording.</p>

        <p>Thank you,<br/><i>ScannerLiveStream</i></p>
      </Container>
    </div>
  );
}


export default AboutComponent;