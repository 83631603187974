import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import EventPlayer from "./EventPlayer";
import { Helmet } from 'react-helmet'
import AdsComponent from '../AdsComponent';
import NavBar from "../Common/NavBar"
import { useSelector, useDispatch } from 'react-redux'
import { useGetEventQuery } from '../features/api/apiSlice'
import queryString from '../query-string';
import {
  Container,
  Label,
  Menu,
  Icon,
  Sidebar,
  Grid,
  Statistic,
  Header

} from "semantic-ui-react";
import "../Call/CallPlayer.css";


// ----------------------------------------------------
function ViewEvent(props) {

  const { id } = useParams();

  const { data: eventData, isSuccess: isEventSuccess } = useGetEventQuery(id);
  const [autoPlay, setAutoPlay] = useState(true);
  const [currentCall, setCurrentCall] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [sidebarOpened, setSidebarOpened] = useState(false);
  const [selectCallId, setSelectCallId] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ogimage = "s3.amazonaws.com/scannerlivestream/thumb-event.jpg";

  const uri = queryString.parse(useLocation().search);
  const pathname = useLocation().pathname;

  let currentCallId = false;

  if (currentCall) {
    currentCallId = currentCall._id;
  }


  const handlePusherClick = () => {
    if (sidebarOpened) setSidebarOpened(false);
  }

  const handleSidebarToggle = () => setSidebarOpened(!sidebarOpened);


  const setStateFromUri = async () => {

    // is this just for one call?
    if (uri.hasOwnProperty('call-id')) {
      const _id = uri['call-id'];
      setSelectCallId(_id);
    }
  }

  const dateRange = (start, end) => {
    if (start.getYear() != end.getYear()) {
      return { "part1": start.toLocaleDateString('en-us', { weekday: "short", year: "numeric", month: "short", day: "numeric" }) + " " + start.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' }), "part2": end.toLocaleDateString('en-us', { weekday: "short", year: "numeric", month: "short", day: "numeric" }) + " " + end.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' }) };
    } else if (start.getMonth() != end.getMonth()) {
      return { "part1": start.toLocaleDateString('en-us', { weekday: "short", month: "short", day: "numeric" }) + " " + start.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' }), "part2": end.toLocaleDateString('en-us', { weekday: "short", month: "short", day: "numeric" }) + " " + end.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' }) };

    } else if (start.getDay() != end.getDay()) {
      return { "part1": start.toLocaleDateString('en-us', { weekday: "short", month: "short", day: "numeric" }) + " " + start.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' }), "part2": end.toLocaleDateString('en-us', { weekday: "short", day: "numeric" }) + " " + end.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' }) };

    } else {
      return { "part1": start.toLocaleDateString('en-us', { weekday: "short", month: "short", day: "numeric" }) + " " + start.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' }), "part2": end.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' }) };

    }
  }
  let dates = { part1: "", part2: "" }
  let expirationDate = ""
  let downloadUrl = false
  if (eventData) {
    downloadUrl = eventData.downloadUrl;
    dates = dateRange(new Date(eventData.startTime), new Date(eventData.endTime))
    expirationDate = new Date(eventData.expireTime).toLocaleDateString('en-us', { weekday: "short", month: "short", day: "numeric" })
  }

  /*
    const dateRange = (start, end) => {
      if (start.getYear() != end.getYear()) {
          return start.toLocaleDateString('en-us', { weekday:"short", year:"numeric", month:"short", day:"numeric"}) + " " + start.toLocaleTimeString() + " - " + end.toLocaleDateString('en-us', { weekday:"short", year:"numeric", month:"short", day:"numeric"}) + " " + end.toLocaleTimeString(); 
      } else if (start.getMonth() != end.getMonth()) {
          return start.toLocaleDateString('en-us', { weekday:"short",  month:"short", day:"numeric"}) + " " + start.toLocaleTimeString() + " - " + end.toLocaleDateString('en-us', { weekday:"short", month:"short", day:"numeric"}) + " " + end.toLocaleTimeString(); 
      
      } else if (start.getDay() != end.getDay()) {
          return start.toLocaleDateString('en-us', { weekday:"short",  month:"short", day:"numeric"}) + " " + start.toLocaleTimeString() + " - " + end.toLocaleDateString('en-us', { weekday:"short", day:"numeric"}) + " " + end.toLocaleTimeString(); 
      
      } else {
          return start.toLocaleDateString('en-us', { weekday:"short",  month:"short", day:"numeric"}) + " " + start.toLocaleTimeString() + " - " + end.toLocaleTimeString(); 
      
      }
    }
    let dates = "";
    if (eventData) {
      dates = dateRange(new Date(eventData.startTime),new Date(eventData.endTime));
     }*/
  return (
    <>
    <Helmet>
      <title>Pierce County Scanner - Events</title>
      <meta property="og:title" content={eventData && eventData.title ? eventData.title : ''} />
      <meta property="og:description" content={eventData && eventData.description ? eventData.description : ''} />
      <meta property="og:image" content={ogimage} />
    </Helmet>
    <div >
        <Menu fixed="top" style={{backgroundColor: "#0d96d4", color:"White", textShadow: "1px 1px 1px #5e5d5d"}}>
        <Link to="/calls"><Menu.Item style={{color:"White", textShadow: "1px 1px 1px #5e5d5d", paddingTop:"1.1em"}} className="" link>Calls</Menu.Item></Link>
        <Link to="/events"><Menu.Item style={{color:"White", textShadow: "1px 1px 1px #5e5d5d", paddingTop:"1.1em"}} className="" link>Events</Menu.Item></Link>
        <span style={{ fontSize: '1.4rem', paddingLeft: '1em', paddingTop: '.5em', color: 'White' }}>
          Pierce & King County Scanner
        </span>
      </Menu>
        <div class= "container main horizontal_ad">
        <center>
          <AdsComponent adSlot="7461549169" />
        </center>
      </div>
        <div className="main container" style={{ paddingTop: '4em' }}>
          <Grid stackable centered>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={8} computer={8}>
                {eventData &&
                  <>
                    <h3>{eventData.title}</h3>
                    <p>{eventData.description}</p>
                    <h4>{eventData.user}</h4>
                  </>
                }
              </Grid.Column>

              <Grid.Column mobile={16} tablet={8} computer={3}>
                <Header as="h2">{dates.part1} <br />? {dates.part2}</Header>
              </Grid.Column>

              <Grid.Column mobile={16} tablet={8} computer={3}>
                <Statistic>
                  <Statistic.Value>{eventData && eventData.numCalls}</Statistic.Value>
                  <Statistic.Label>Calls</Statistic.Label>
                </Statistic>
              </Grid.Column>

              <Grid.Column mobile={16} tablet={8} computer={4}>
                {downloadUrl &&
                  <a href={downloadUrl}>
                    <Statistic small className="desktop-only">
                      <Statistic.Value><Icon name="download" /></Statistic.Value>
                      <Statistic.Label>Download Event</Statistic.Label>
                    </Statistic>
                  </a>
                }
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>

        {eventData && <EventPlayer eventData={eventData} selectCallId={selectCallId} />}
      </div>
    </>
  );
}


export default ViewEvent;
